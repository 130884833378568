import type { ServerResponse } from 'http'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'

import * as dompurify from 'isomorphic-dompurify'

type CompactedObject<R> = { [x: string]: Exclude<R, null | undefined> }
export function compactObject<R> (obj: { [x: string]: R }) {
  if (typeof obj !== 'object') { return {} }

  return pickBy<R>(obj, identity) as CompactedObject<R>
}

export function safeClassName (string: string) {
  return string.split(/[^\w-]+/g).filter(Boolean).join('-').toLowerCase()
}

export function getLocationOrigin () {
  if (window.location.origin) {
    return window.location.origin
  }

  const {
    protocol,
    hostname,
    port,
  } = window.location

  return `${protocol}//${hostname}${port ? ':' + port : ''}`
}

export function isServer () {
  return typeof window === 'undefined'
}

export const serverSideRedirect = (res: ServerResponse, path = '/login', moreHeaders = {}, statusCode = 302) => {
  res.writeHead(statusCode, {
    Location: path,
    ...moreHeaders,
  }).end()
}

export const compose = <A, C> (...functions: ((argument: A, context: C) => A)[]) =>
  (argument: A, context: C) =>
    functions.reduce(
      (value, func) => func(value, context),
      argument,
    )

type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T
export const truthy = <T>(value: T): value is Truthy<T> => !!value

export const sanitizeInput = (dirty: string): string => {
  return dompurify.sanitize(dirty)
}
