import 'intersection-observer'
import { isServer } from './utilities'

if (process.env.NODE_ENV === 'development') {
  require('@webcomponents/shadydom') // Enable local testing with IE11, https://github.com/vercel/next.js/issues/13231
}

if (!isServer() && !document.contains) {
  document.contains = (node) => document.body.contains(node) // react-hook-form uses document.contains
}
